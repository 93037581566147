import React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import HeroInner from "../components/heroinner"
import StatutoryCompliancePic from "../static/images/Statutory-Compliance.png"

export default function Statutory() {
  const heroTitle = "Statutory"
  const heroImage = "/images/slider/Services--Banner.jpg"
  const heroMobileImage = "/images/slider/mobile/Services--Banner.jpg"

  return (
    <>
      <Seo title={heroTitle} />
      <HeroInner heroTitle={heroTitle} heroImage={heroImage} heroMobileImage={heroMobileImage} />
      <section class="c-section c-section-sm">
        <div class="container">
          <div class="row">
            <aside class="col-12 col-lg-3 col-md-4 order1">
              <Link to="/" className="back--home">
                <i class="fas fa-chevron-left"></i> <span>Back to homepage</span>
              </Link>
            </aside>

            <div class="col-12 col-lg-9 col-md-8 order2">
              <div class="innerpage--content innerpage--content-sm">
                <h2 class="h2 h2-sm">{heroTitle}</h2>
                <ul>
                  <li>
                    <a href="/docs/Investor_Charter.pdf" target="_blank" rel="noopener">
                      Investor Charter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.google.com/document/d/1QXuHJqxFcglQ8iMxQTFWyferFP7hXWGR/export?format=pdf&rel=ias.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      IA Monthly Disclosures of Investor Complaints
                    </a>
                  </li>
                  <li>
                    <a href="/docs/Complaints_Redressal.pdf" target="_blank" rel="noopener">
                      Complaints Redressal
                    </a>
                  </li>
                </ul>
                <p className="mb-4">
                  Disclosure with respect to compliance with Annual compliance audit requirement
                  under Regulation 19(3) of SECURITIES AND EXCHANGE BOARD OF INDIA (INVESTMENT
                  ADVISERS) REGULATIONS, 2013 for last and current financial year are as under:
                </p>
                <img src={StatutoryCompliancePic} alt="Statutory Compliance" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
